<template>
  <div class="content">
    <el-tabs v-model="tabModel" type="border-card" @tab-click="handleTabClick">
      <el-tab-pane
        v-loading="loading"
        element-loading-text="data loading"
        name="tab1"
        class="tab"
      >
        <span slot="label"
          ><i class="el-icon-date"></i>{{ detailManagerText }}</span
        >
        <SpecTabPane
          :itemList="itemList"
          :formData="formData"
          :revisionData="revisionData"
          :otherNotesData="otherNotesData"
          :pageStatus="pageStatus"
          :selectProductType="selectType"
          :language="language"
          v-if="itemList.length > 0"
        ></SpecTabPane>
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        v-for="(item, index) in tabList"
        :key="index"
        v-loading="loading"
        element-loading-text="data loading"
        :name="'tab' + (index + 2)"
        class="tab"
      >
        <span slot="label"
          ><i class="el-icon-date"></i> {{ versionText }}
          {{ item.revNumber }}</span
        >
        <SpecTabPane
          :itemList="itemList"
          :formData="formData"
          :revisionData="revisionData"
          :pageStatus="pageStatus"
          :selectProductType="selectType"
          :language="language"
          v-if="itemList.length > 0"
        ></SpecTabPane>
      </el-tab-pane>
    </el-tabs>

    <RevPopover
      :pageStatus="pageStatus"
      :versionInfo="versionInfo"
      :language="language"
    ></RevPopover>
  </div>
</template>

<script>
import { EventBus } from "./config/eventBus";
import * as TypeConfig from "./config/allTypeConfig";
import * as StructConvertUtils from "./config/structConvertUtils";

import SpecTabPane from "./SpecTabPane.vue";
import RevPopover from "./components/RevPopover.vue";

export default {
  components: { SpecTabPane, RevPopover },
  data() {
    return {
      loading: true,
      language: "CN", //EN、CN
      tabModel: "tab1",
      selectType: "CSFMU-B", // 当前选中的产品类型
      pageStatus: "Add", // 页面状态 Add、Edit、Detail
      itemList: [], //根据类型获取模板拥有的模块
      baseInfoData: {},
      generalSpecificationData: {},
      analysisData: {},
      fattyAcidData: {},
      microbiologicalTestsData: {},
      otherNotesData: {},
      revisionData: [],
      defaultData: {}, // 用于记录初始对象
      formData: {}, // 用于存储所有字段的值
      loadSpecData: {}, // api 加载的数据
      isPrintVerify: false, // 是否打印 verify 信息
      tabList: [],
      versionInfo: {}, //版本信息
      recoverData: {},
      saveCustomFieldData: {}, //自定义字段数据
    };
  },
  provide() {
    return {
      getControlDataMethod: this.getControlData,
      getFormDataMethod: this.getSaveData,
      getInitRevisionDataMethod: this.getInitRevisionData,
      loadDetailDataMethod: this.loadData,
      loadRecoverDataMethod: this.loadRecoverData,
      setCustomFieldsMethod: this.setCustomFields,
      getGeneralSpecificationDataMethod: this.getGeneralSpecificationData,
      setShowNoteValueMethod: this.setShowNoteValueData
    };
  },
  computed: {
    detailManagerText: function () {
      return this.language == "CN" ? "编辑栏" : "Detail Manager";
    },
    versionText: function () {
      return this.language == "CN" ? "版本" : "Version";
    },
  },
  created() {
    this.pageStatus = "Add";
    let params = { ...this.$route.query };
    if (params.id) {
      this.pageStatus = "Edit";
    }
    if(params.language){
      this.language = params.language;
    }else{
      this.language = "EN";
    }
    // 默认加载数据
    this.loadData();
    // 监听类型选择事件
    EventBus.$on("productTypeChangeEvent", this.productTypeChangeEvent);
    EventBus.$on("sendTagDataEvent", this.tagDataEvent);
  },
  methods: {
    setShowNoteValueData(val){
      this.otherNotesData.list["is_show_note"].value = val;
    },
    tagDataEvent(data) {
      let name = "";
      if (this.language == "CN") {
        let mappingLanage = new Map([
          [ "成分","ingredients"],
          ["通用名/其他名称","common_other_name"],
          [ "INCI名称","inci_name"],
          [ "植物学名","botanical_name"],
          [ "使用部位","part_used"],
          [ "百分比","percentage"],
        ]);
        name = mappingLanage.get(data.name);
      }else{
        name = data.name.toLowerCase().replaceAll(' ', '_');
      }
      
      if(this.generalSpecificationData.list[name]){
        this.generalSpecificationData.list[name].value = data.value;
      }
      
    },
    setCustomFields(params, fieldsObj) {
      
      this.saveCustomFieldData[params.item.attr] = fieldsObj;
      console.info('params.item.attr:',params.item.attr)
      // console.log("this.saveCustomFieldData",this.saveCustomFieldData);
      // console.info('fieldsObj:',fieldsObj)
    },
    getGeneralSpecificationData() {      return this.generalSpecificationData;
    },
    handleTabClick(tab) {
      if (tab.name === "tab1") {
        let params = { ...this.$route.query };
        if (params.id) {
          this.pageStatus = "Edit";
        } else {
          this.pageStatus = "Add";
        }
        let productType = this.loadSpecData.productType;
        this.dataTransfer(this.loadSpecData, productType);
      } else {
        this.pageStatus = "Detail";
        let tidx = tab.name.substring(3, tab.name.length);
        let data = this.tabList[tidx - 2];
        let productType = data.productType;
        this.dataTransfer(data, productType);
      }
    },
    loadRecoverData() {
      let num = this.tabModel.substring(3, this.tabModel.length);
      this.tabModel = "tab1";
      this.pageStatus = "Edit";
      this.dataTransfer(
        this.recoverData[num - 2],
        this.recoverData.productType
      );
    },
    loadData() {
      if (this.pageStatus != "Add") {
        let params = {
          id: this.$route.query.id,
        };
        let apiDetail = "";
        let apiAuditedDetail = "";
        if (this.language == "CN") {
          apiDetail = this.$api.handleSpec.getNewCnSpecDetail(params);
          apiAuditedDetail =
            this.$api.handleSpec.getNewCnSpecAuditedDetail(params);
        } else {
          apiDetail = this.$api.handleSpec.getNewSpecDetail(params);
          apiAuditedDetail =
            this.$api.handleSpec.getNewSpecAuditedDetail(params);
        }
        apiDetail.then((data) => {
          // 如果是 isCopy 为 true，则设置 itemCode、revision为空
          let resData = data;
          console.info('this.$route.query.isCopy00:',this.$route.query.isCopy)
          if(this.$route.query.isCopy==='true'){
            resData.id = "";
            resData.itemCode = "";
            let jsonValue = JSON.parse(data.jsonValue);
            jsonValue.base_info.item_code = ''
            jsonValue.revision = []
            resData.jsonValue = JSON.stringify(jsonValue);
          }
          this.loadSpecData = resData;
          let productType = data.productType;
          this.dataTransfer(data, productType);
        });
        apiAuditedDetail.then((data) => {
          this.recoverData = data;
          this.tabList = data;
        });
      } else {
        let initData = "";
        let productType = "";
        if (this.language == "CN") {
          productType = "TY-SHXTO";
          initData = TypeConfig.typeCNData[0].data;
          
        } else {
          productType = "CSFMU-B";
          initData = TypeConfig.typeData[0].data;
          console.info('initData:',initData)
          
        }
        this.dataTransfer(initData, productType);
      }
    },
    getControlData(item) {
      return this[item];
    },
    getInitRevisionData() {
      let revisionData = {
        revisionDate: "",
        requestedBy: "",
        revisedNotes: "",
        editedBy: "",
        reason: "",
        approvedBy: "",
        isComplete: false,
      };
      return revisionData;
    },
    getSaveData() {
      console.info("this.saveCustomFieldData:", this.saveCustomFieldData);
      this.baseInfoData.list["custom_field"] =
        this.saveCustomFieldData["custom_field"];

      this.generalSpecificationData.list["custom_field_before_regular_data"] =
        this.saveCustomFieldData["custom_field_before_regular_data"];

      this.generalSpecificationData.list["custom_field"] =
        this.saveCustomFieldData["gs_custom_field"];

      //左右两侧数据
      let microbiologicalTestsData2 = this.microbiologicalTestsData;
      let mtList = this.saveCustomFieldData["left_mt_custom_field"];
      let mtRightList = this.saveCustomFieldData["right_mt_custom_field"];
      if (mtList) {
        let arr = [];
        for (let i = 0; i < mtList.length; i++) {
          let item = mtList[i];
          let rightItem = mtRightList[i];
          arr.push({
            fields: item.fields,
            specification: item.specification,
            test_method: rightItem.test_method,
          });
        }
        let len = this.microbiologicalTestsData.leftList.length;
        microbiologicalTestsData2.leftList[len - 1].value = arr;
        microbiologicalTestsData2.leftList["mt_custom_field"] = arr;
      }

      let fattyAcidData2 = this.fattyAcidData;
      let faList = this.saveCustomFieldData["left_fa_custom_field"];
      let faRightList = this.saveCustomFieldData["right_fa_custom_field"];
      if (faList) {
        let arr = [];
        for (let i = 0; i < faList.length; i++) {
          let item = faList[i];
          let rightItem = faRightList[i];
          arr.push({
            fields: item.fields,
            specification: item.specification,
            test_method: rightItem.test_method,
          });
        }
        let len = this.fattyAcidData.leftList.length;
        fattyAcidData2.leftList[len - 1].value = arr;
        fattyAcidData2.leftList["fa_custom_field"] = arr;
      }

      let analysisData2 = this.analysisData;
      let anList = this.saveCustomFieldData["left_an_custom_field"];
      let anRightList = this.saveCustomFieldData["right_an_custom_field"];
      if (anList) {
        let arr = [];
        for (let i = 0; i < anList.length; i++) {
          let item = anList[i];
          let rightItem = anRightList[i];
          arr.push({
            fields: item.fields,
            specification: item.specification,
            test_method: rightItem.test_method,
          });
        }
        //TODO:替换最custom_field
        let len = this.analysisData.leftList.length;
        analysisData2.leftList[len - 1].value = arr;
        analysisData2.leftList["an_custom_field"] = arr;
      }

      let model = {
        baseInfoData: this.baseInfoData,
        generalSpecificationData: this.generalSpecificationData,
        analysisData: analysisData2,
        fattyAcidData: fattyAcidData2,
        microbiologicalTestsData: microbiologicalTestsData2,
        otherNotesData: this.otherNotesData,
        revisionData: this.revisionData,
        revNumber: this.loadSpecData.revNumber,
        verified_time: this.loadSpecData.verifiedTime,
        rev_number: this.loadSpecData.revNumber,
      };
      console.info("model:", model);
      return model;
    },
    setDetailToDisable(obj, flag) {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            this.setDetailToDisable(obj[key], flag); // 递归处理子对象
          } else if (key === "disabled") {
            obj[key] = flag; // 将 disabled 设置为 true
          }
        }
      }
    },
    dataTransfer(data, productType) {
      StructConvertUtils.pageStatus = this.pageStatus;
      this.selectType = productType;

      if (data.jsonValue) {
        let jsonValue = JSON.parse(data.jsonValue);
        if (
          !jsonValue.revision ||
          !Array.isArray(jsonValue.revision) ||
          jsonValue.revision.length === 0 ||
          !jsonValue.revision[jsonValue.revision.length - 1].isComplete
        ) {
          this.revisionData.push(this.getInitRevisionData());
        }else{
          this.revisionData = jsonValue.revision;
        }
      } else {
        if(this.revisionData.length==0){
          this.revisionData.push(this.getInitRevisionData());
        }
        
      }

      // 记录版本信息
      this.setupVersionInfo(data);

      // 默认加载的模块
      console.info("productType:", productType);
      this.itemList = TypeConfig.config.getFirstLayerAttribute(
        productType,
        this.language
      );

      let loadData = "";
      if (this.pageStatus == "Edit" || this.pageStatus == "Detail") {
        loadData = JSON.parse(data.jsonValue);
      } else {
        //添加 使用模板数据
        // loadData = TypeConfig.typeTestData[0].data;

        loadData = this.getAddPageData(productType).data;

        this.loading = false;
      }

      //模板数据
      let templateData = this.getAddPageData(productType);

      // 如果是详情模式则所有控件设置disable为 true
      if (this.pageStatus == "Detail") {
        this.setDetailToDisable(templateData.data, true);
      } else {
        this.setDetailToDisable(templateData.data, false);
      }
      //转换数据
      const transformData = StructConvertUtils.transformMixedData(
        loadData,
        templateData.data,
        this.pageStatus
      );

      console.log("加载转换后的数据:", transformData);

      // 设置自定义字段的数据赋值
      this.setLoadDataSetupCustomField(transformData);

      Object.keys(TypeConfig.DataKeys).forEach((key) => {
        const dataKey = this.toCamelCase(TypeConfig.DataKeys[key]) + "Data";
        let rowNumber =
          key === "BASE_INFO" ||
          key === "GENERAL_SPECIFICATION" ||
          key === "OTHER_NOTES"
            ? 1
            : 2;

        //处理数据
        const lowerKey = key.toLocaleLowerCase();
        let list = transformData[lowerKey];

        list = this.getFullList(list);
        if (list) {
          this[dataKey] =
            key === "BASE_INFO" ||
            key === "GENERAL_SPECIFICATION" ||
            key === "OTHER_NOTES"
              ? {
                  list,
                  type: TypeConfig.DataKeys[key],
                  row: rowNumber,
                  model: dataKey,
                }
              : {
                  ...this.getLeftRightList(list),
                  type: TypeConfig.DataKeys[key],
                  row: rowNumber,
                  model: dataKey,
                };

          // console.info("this[dataKey]:", this[dataKey]);
          //base_info、general_specification、analysis、microbiological_tests、fatty_acid、other_notes
          this.$set(this.formData, TypeConfig.DataKeys[key], {});
        }
      });
      // this.defaultData = this.formData;
      this.loading = false;
    },
    setLoadDataSetupCustomField(data) {
      //base_info 的 custom_field
      this.saveCustomFieldData['custom_field'] = data.base_info.custom_field.value;
      //gs
      this.saveCustomFieldData['gs_custom_field'] = data.general_specification.custom_field.value;
      this.saveCustomFieldData['custom_field_before_regular_data'] = data.general_specification.custom_field_before_regular_data.value;
      console.info("setup --data");
      //an
      //mt
      //fn
    },
    getAddPageData(productType) {
      let templateData = {};
      if (this.language == "CN") {
        templateData = TypeConfig.typeCNData.find(
          (item) => item.type === productType
        );
      } else {
        templateData = TypeConfig.typeData.find(
          (item) => item.type === productType
        );
      }
      return templateData;
    },
    setupVersionInfo(data) {
      if (this.pageStatus == "Edit") {
        // 记录版本信息
        this.versionInfo = {
          createName: data.createName ? data.createName : "",
          createTime: data.createTime ? data.createTime.substring(0, 10) : "",
          revNumber: data.revNumber ? data.revNumber : "",
          updateName: data.updateName ? data.updateName : "",
          updateTime: data.updateTime ? data.updateTime.substring(0, 10) : "",
          verifiedName: data.verifiedName ? data.verifiedName : "",
          verifiedTime: data.verifiedTime
            ? data.verifiedTime.substring(0, 10)
            : "",
        };
      }
    },
    productTypeChangeEvent(data) {
      this.selectType = data.type;
      if (this.pageStatus == "Add") {
        let templateData = this.getAddPageData(this.selectType);
        this.dataTransfer(templateData.data, this.selectType);
      } else {
        this.dataTransfer(this.loadSpecData, this.selectType);
      }
    },
    toCamelCase(str) {
      return str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
    },
    formatTitle(str) {
      return str
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b\w/g, (match) => match.toUpperCase());
    },
    mapAttributeModel(str) {
      return this[str.replace(/_([a-z])/g, (g) => g[1].toUpperCase()) + "Data"];
    },
    getFullList(data) {
      if (!data) {
        return data;
      }
      Object.keys(data).forEach((key) => {
        const fieldKeyData = data[key];
        if (fieldKeyData.type == "custom_input") {
          // console.info("fieldKeyData.attr:", fieldKeyData.attr);
          // console.info("fieldKeyData.value:", fieldKeyData.value);
          // console.info('fieldKeyData.value == true?',fieldKeyData.value == "")
          if (fieldKeyData.value == "") {
            fieldKeyData.value = [
              {
                fields: "",
                specification: "",
                test_method: "",
                value: "",
              },
            ];
          }
        }
      });
      return data;
    },
    getLeftRightList(data) {
      if (!data) return;

      const leftList = [];
      const rightList = [];

      Object.keys(data).forEach((standardKey) => {
        const standard = data[standardKey];

        if (standardKey === "custom_field") {
          if (standard.value == "" || standard.value.length == 0) {
            standard.value = [
              { fields: "", specification: "", test_method: "" },
            ];
          }
        }

        if (standard.type === "custom_input") {
          const customField = this.getCustomeFireld(standard);
          leftList.push(customField);
          rightList.push(customField);
        } else {
          const label =
            standard.label.charAt(0).toUpperCase() + standard.label.slice(1);
          const commonFields = {
            label: label,
            tips_value: standard.tips_value,
            disabled: standard.disabled,
            fields: standard.fields,
            value: standard.value.specification,
          };
          leftList.push({ type: "input", ...commonFields });

          commonFields.value = standard.value.test_method;
          rightList.push({ type: "input", ...commonFields });
        }
      });

      return { leftList, rightList };
    },
    getCustomeFireld(standard) {
      return {
        type: "custom_input",
        label: standard.label,
        tips_value: standard.tips_value,
        disabled: standard.disabled,
        value: standard.value || "",
        fields: standard.fields || "",
        specification: standard.specification || "",
        test_method: standard.test_method || "",
        attr: standard.attr,
      };
    }
  },
};
</script>

<style>
@import "../style/new-spec.css";

.tab {
  min-height: 500px;
}
</style>